html,body,#root{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

* {
  margin: 0;
}


.black .input-icon svg path{
  fill: white;
}

.black .header-title {
  color: white;
}