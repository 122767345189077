input,
textarea {
  background: none;
  color: #c6c6c6;
  font-size: 18px;
  padding: 15px;
  display: block;
  border: none;
  border-radius: 5px;
  border: 1px solid #DADADA;
  color:black;
  width: 100%;
}

input:hover {
  border: 1px solid #DADADA;
}

input:focus,
textarea:focus {
  outline: none;
  border: 1px solid #DADADA;
}

input:focus~label,
input:valid~label,
textarea:focus~label,
textarea:valid~label {
  top: -5px;
  font-size: 12px;
  color: #000;
  left: 11px;
}

input:focus~.bar:before,
textarea:focus~.bar:before {
  width: 320px;
}

input[type="password"] {
  letter-spacing: 0.3em;
}

.group {
  position: relative;
  display: flex;
  margin-bottom: 34px;
}

.group span {
  position: absolute;
  bottom: -13px;
  font-size: 10px;
  color:red
}

.group.-error *{
  border-color: red;
}

label {
  color: #c6c6c6;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 17px;
  transition: 300ms ease all;
  background-color: #fff;
}

.black label {
  background-color:rgb(30, 30, 30);
}

.black input {
  color:white;
}

.black input:focus~label,
.black input:valid~label,
.black textarea:focus~label,
.black textarea:valid~label {
  color: white;
}

.black input:focus,
.black textarea:focus {
  border-color: white;
}

.opacity {
  opacity: .4;
}

.input-icon {
  position: absolute;
  right: 10px;
  top:17px;
}

.input-icon.-position {
  right: 10px;
}