/* body {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  min-height: 100vh;
} */

body * {
  font-family: 'Roboto';
}

.static  {
  padding-top: 20px;
}
.static h2 {
  margin-bottom: 20px;
}

.black {
  background-color: rgb(30, 30, 30);
  width: 100%;
  height: 100%;
}

.black .form-group .sub-titile {
  color:white
}

.black .button {
  border:1px solid white;
}

.black h3, .black h2 {
  color: white;
}

a {
  color: #666;
}

h1 {
  font-weight: bold;
  margin: 0 0 10px;
}

h4 {
  /* margin-bottom: 30px; */
}

h1,
h2,
h4 {
  text-align: center;
}


form {  
  margin: 0 20px;
  margin-top: 20px;
}

.form-actions {
  margin-top: 15px;
}


.App-cards {
  margin: 0 auto;
  max-width: 1280px;
  text-align: center;
}

.App-cards-list {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.App-cards-list>* {
  transform: scale(0.8);
  margin-bottom: 30px !important;
}

.App-highlight {
  font-family: serif;
  margin: 15px auto 0;
  max-width: 300px;
}

.App-highlight>div {
  padding-left: 40px;
}

.App-badges {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-badges a {
  display: block;
}

.App-badges a+a {
  margin-top: 18px;
}

.github__btn {
  align-items: center;
  background-color: #ccc;
  border-radius: 4px;
  color: #444;
  display: flex;
  line-height: 1;
  padding: 8px 12px;
}

.github__btn:hover {
  color: #444;
  text-decoration: none;
}

.github__btn img {
  display: inline-block;
  height: 28px;
  margin-right: 8px;
}

.App-credits {
  background-color: #000;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.App-credits a {
  color: #fff;
  font-weight: bold;
}


.form-group {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 98%;
}

.row > div {
  width: 48%;
  height: 100%;
}

.row div input{
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #DADADA;
}

.form-group input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #DADADA;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form-actions div {
  background-color: #F5BF3C;
  border-radius: 100px;
  color: #261A00;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button__text{
  font-size: 18px;
  font-weight: 900;
}

.marg-t {
 
  /* width: 100%!important; */
}

.marg-t .group {
  margin-bottom: 0;
}

.example {
  width: 115px;
  height: 35px;
  border-radius: 15px;
  background-color: black;
  color:white;
  outline: none;
  border:none
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-titile {
  font-size: 20px;
  font-weight: 900;
  margin-left: 10px;
}

@media screen and (min-width: 600px) {
  .App-badges {
    flex-direction: row;
  }

  .App-badges a+a {
    margin-top: 0;
    margin-left: 18px;
  }
}




/* only animate if the device supports hover */
@media (hover: hover) {
  #creditcard {
    /*  set start position */
    transform: translateY(110px);
    transition: transform 0.1s ease-in-out;
    /*  set transition for mouse enter & exit */
  }

  #money {
    /*  set start position */
    transform: translateY(180px);
    transition: transform 0.1s ease-in-out;
    /*  set transition for mouse enter & exit */
  }

  button:hover #creditcard {
    transform: translateY(0px);
    transition: transform 0.2s ease-in-out;
    /*  overide transition for mouse enter */
  }

  button:hover #money {
    transform: translateY(0px);
    transition: transform 0.3s ease-in-out;
    /*  overide transition for mouse enter */
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.25rem);
  }

  100% {
    transform: translateY(0);
  }
}

.button:hover .button__text span {
  transform: translateY(-0.25rem);
  transition: transform .2s ease-in-out;
}

h4 {
  font-weight: 100;
  color:gray
}

/* styling */

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");


.header {
  padding: 0 15px;
}

.header .logo {
  margin-top: 8px;
}

.header-title{
  color: black;
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 8px;
}

.header-sub-title{
  color: #8C8C8C;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 25px;
}